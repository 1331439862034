<template>
    <div class="unterseite impressum">
        <div class="back-container">
            <button class="btn-unstyled btn-back" @click="goBack()">
                <i class="fas fa-chevron-left"></i>
                <span>{{lang.spielStarten.buttonTextBack}}</span>
            </button>
        </div>
        <h1>Impressum</h1>
        <h2>Angaben gemäß § 5 TMG</h2>
        <p>
            Florian Fricke <br> 
            Deubener Straße 19 <br> 
            01159 Dresden <br> 
            <a href= "mailto:info@beerpongfriends.com">info@beerpongfriends.com</a><br>
            +49 (0) 351 21925591
        </p>
        <br>
        <p>Der Betreiber versichert ausdrücklich alle Inhalte und Angebote dieser Internetpräsenz sorgfältig auf Inhalt und gegen rechtliche Verstöße überprüft zu haben. Sollten dennoch Inhalte oder die Gestaltung dieser Internetpräsenz insgesamt oder zum Teil Rechte Dritter oder gesetzliche Bestimmungen verletzten oder wettbewerbsrechtliche Probleme in jedweder Form verursachen, so bitten wir um eine kurze, formlose, ausreichend erläuternde Nachricht. Der Betreiber garantiert, dass die zu Recht beanstandeten Passagen unverzüglich entfernt bzw. den rechtlichen Vorgaben entsprechend angepasst werden, ohne dass Ihrerseits das Hinzuziehen eines Rechtsbeistandes erforderlich ist. Die zeit- und kostenaufwändige Einschaltung eines Anwaltes zur kostenpflichtigen Abmahnung des Betreibers entspricht nicht dessen wirklichen oder mutmaßlichen Willen. Bitte beachten Sie in diesem Zusammenhang auch §8 Abs. 4 UWG.</p>
    </div>
</template>

<script>
import store from '@/store/index'
import router from '@/router/index'

export default {
    name: 'Impressum',
    data() {
        return {
        }
    },
    methods: {
        goBack() {
            router.go(-1);
        }
    },
    computed: {
        lang() {
            return store.getters.getLanguage;
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../../assets/scss/base";

</style>